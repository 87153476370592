import React, { useEffect } from "react"

import { useStaticQuery, graphql } from "gatsby"
import { Link as RouterLink } from "gatsby-plugin-react-i18next"

import { Typography, Box, Link as StyleLink } from "@material-ui/core"

const NotFound = () => {
  useEffect(() => {
    console.log("=== 404 ===")
  })

  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
        }
      }
      pages: allSitePage(filter: { path: { regex: "/^((?!404).)*$/" } }) {
        totalCount
        nodes {
          path
        }
      }
    }
  `)

  return (
    <Box margin={8} textAlign="center">
      <Typography paragraph variant="h4">
        Cette page n'existe pas : erreur 404
      </Typography>
      <Typography paragraph>
        {data.pages.totalCount} pages trouvées :
      </Typography>
      {data.pages.nodes.map(node => (
        <StyleLink component={RouterLink} to={node.path}>
          <Typography>{data.site.siteMetadata.siteUrl + node.path}</Typography>
        </StyleLink>
      ))}
    </Box>
  )
}

export default NotFound
